import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import history from '~/services/history';

import { taskHourUpdateRequest } from '~/store/modules/taskHour/actions';

import { PauseCircleFilled } from '@ant-design/icons';
import { Container } from './styles';

export default function TaskPlay() {
  const dispatch = useDispatch();

  const task = useSelector(store => store.taskHour.playing);

  if (!task) return null;

  function onPause() {
    if (window.confirm('Deseja encerrar a contagem de tempo desta tarefa?')) {
      dispatch(taskHourUpdateRequest({
        id: task?.timeId,
        projectId: task?.projectId,
        taskId: task?.id,
      }));
    }
  }

  return (
    <Tooltip title={(
      <>
        <b>#{task.index}</b> {task.name}<br />
      </>
    )}>
      <Container
        onClick={() => { history.push(`/projects/${task.projectId}/sprints/${task.sprintId}/tasks/${task.id}`) }}
        type={task.type}
      >
        <PauseCircleFilled onClick={() => onPause()} />
      </Container>
    </Tooltip >
  );
}