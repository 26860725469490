import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import PerfectScroll from 'react-perfect-scrollbar';

import history from '~/services/history';

import MenuContent from '~/components/Menu';
import HeaderContent from '~/components/Header';

import { taskHourUpdateRequest } from '~/store/modules/taskHour/actions';

import { HomeFilled } from '@ant-design/icons';
import { Container, Content, Wrapper, Layout, Header, Body, Sider, Logo } from './styles';

export default function DefaultLayout({ children }) {
  const dispatch = useDispatch();

  const task = useSelector(store => store.taskHour.playing);

  const [collapsed, setCollapsed] = useState(isMobile ? true : false);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [isAfk, setIsAfk] = useState(false);

  function onCollapse(collapsed) {
    setCollapsed(collapsed);
  }

  useEffect(() => {
    const handleActivity = () => {
      setLastActivity(Date.now());
      if (isAfk) {
        setIsAfk(false);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        handleActivity();
      }
    };

    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    const checkAfk = setInterval(() => {
      if (Date.now() - lastActivity > 60 * 60 * 1000) {
        if (!isAfk) {
          setIsAfk(true);
          callEndpoint();
        }
      }
    }, 10000);

    return () => {
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(checkAfk);
    };
  }, [lastActivity, isAfk]);

  const callEndpoint = async () => {
    try {
      if (!task) return null;

      toast.warn('Sua tarefa está sendo encerrada por inatividade');

      dispatch(taskHourUpdateRequest({
        id: task?.timeId,
        projectId: task?.projectId,
        taskId: task?.id,
      }));
    } catch (error) {
      //
    }
  };

  return (
    <Container>
      <PerfectScroll style={{ height: '100%', position: 'fixed' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} className={isMobile ? 'isMobile' : ''}>
          <div className="logo">
            <Logo onClick={() => history.push('/')} className={`${collapsed && 'is-collapsed'}`}>
              <div>
                <span>StationUp</span>
                <span>DevOps</span>
              </div>
            </Logo>

          </div>
          <MenuContent />
        </Sider>
      </PerfectScroll>

      <Layout style={{ marginLeft: collapsed ? '80px' : '200px', transition: `all ${collapsed ? '0.7' : '0.2'}s` }}>
        <Header>
          <HeaderContent />
        </Header>

        <Body>
          <Wrapper>
            <Content className={`main ${isMobile ? 'is-mobile' : ''}`}>
              {children}
            </Content>
          </Wrapper>
        </Body>
      </Layout>
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
